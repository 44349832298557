import React, { PureComponent } from 'react'

import Title from '../Title'
import Subtitle from '../Subtitle'
import Text from '../Text'
import './styles.css'

export default class Privacy extends PureComponent {
  render() {
    return (
      <div className="Privacy">
        <div className="Privacy-container">
          <div className="Privacy-title">
            <Title>Privacy Policy</Title>
          </div>
          <div className="Privacy-content">
            <div className="Privacy-bullet">
              <Subtitle>About the app</Subtitle>
              <div className="Privacy-textContainer">
                <div className="Privacy-bulletTitle">
                  Who May Use the App and Services
                </div>
                <div className="Privacy-text">
                  <Text>
                    You may use the App and Services if you are 13 years or
                    older and are not barred from using the Services under
                    applicable law.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    Additionally, to use the Keyruptive App and Services you’ll
                    have to register an account via the App, through which all
                    of Keyruptive's functionality is made available.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    You agree that you won’t disclose your Account credentials
                    to anyone and you’ll notify us immediately of any
                    unauthorized use of your Account. You’re responsible for all
                    activities that occur under your Account, whether or not you
                    know about them. We reserve the right to suspend or
                    terminate your Account if you provide inaccurate, untrue, or
                    incomplete information, or if you fail to comply with the
                    account registration requirements.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    Keyruptive uses third-party storage services for secure
                    management of your sensitive information. Keyruptive does
                    not have access to those third-party service credentials at
                    any time thus you also agree you won’t disclose any of your
                    third-party accounts credentials to anyone and you’ll notify
                    us immediately of any unauthorized use of such credentials.
                  </Text>
                </div>
              </div>
              <div className="Privacy-textContainer">
                <div className="Privacy-bulletTitle">
                  Registering with Keyruptive
                </div>
                <div className="Privacy-text">
                  <Text>
                    To register a new account and be registered with Keyruptive
                    via the App, you need to provide personal data, such as
                    details of your identity, telephone number, email address,
                    and set up your account security. You also need to agree to
                    be bound by these Terms and Conditions, all applicable laws
                    and regulations, and agree that you are responsible for
                    obeying any laws which apply to you.
                  </Text>
                </div>
              </div>
              <div className="Privacy-textContainer">
                <div className="Privacy-bulletTitle">Closing the account</div>
                <div className="Privacy-text">
                  <Text>
                    We may terminate your access to and use of the Services, at
                    our sole discretion, at any time and without notice to you.
                    You may close your Account at any time by following the
                    account closure instructions in the App.
                  </Text>
                </div>
              </div>
            </div>
            <div className="Privacy-bullet">
              <Subtitle>Disclaimer</Subtitle>
              <div className="Privacy-textContainer">
                <div className="Privacy-text">
                  <Text>
                    Your use of the Keyruptive App and Services is at your sole
                    risk. These are provided on an "AS IS" and "AS AVAILABLE"
                    basis. The App and Services are provided without warranties
                    of any kind, whether express or implied, including, but not
                    limited to, implied warranties of merchantability, fitness
                    for a particular purpose, non-infringement or course of
                    performance.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    Keyruptive Technologies, its subsidiaries, affiliates, and
                    licensors do not warrant that a) the Service will function
                    uninterrupted, secure or available at any particular time or
                    location; b) any errors or defects will be corrected; c) the
                    Service is free of viruses or other harmful components; or
                    d) the results of using the Service will meet your
                    requirements.
                  </Text>
                </div>
              </div>
            </div>
            <div className="Privacy-bullet">
              <Subtitle>Third Party Websites and Services</Subtitle>
              <div className="Privacy-textContainer">
                <div className="Privacy-text">
                  <Text>
                    Our Service may contain links to third-party websites or
                    services that are not owned or controlled by Keyruptive.
                    Additionally, our Service may allow you to interact directly
                    with third-party services without leaving our website or
                    mobile application.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    Keyruptive has no control over, and assumes no
                    responsibility for, the content, privacy policies, or
                    practices of any third party web sites or services. You
                    further acknowledge and agree that Keyruptive shall not be
                    responsible or liable, directly or indirectly, for any
                    damage or loss caused or alleged to be caused by or in
                    connection with use of or reliance on any such content,
                    goods or services available on or through any such websites
                    or services.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    We strongly advise you to read the terms and conditions and
                    privacy policies of any third-party web sites or services
                    that you visit.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    We reserve the right to terminate or suspend access to these
                    third-party services immediately, without prior notice or
                    liability, for any reason whatsoever.
                  </Text>
                </div>
              </div>
            </div>
            <div className="Privacy-bullet">
              <Subtitle>Limitation of Liability</Subtitle>
              <div className="Privacy-textContainer">
                <div className="Privacy-text">
                  <Text>
                    To the maximum extent not prohibited by law, Keyruptive
                    Technologies shall not be liable for damages of any type,
                    whether direct or indirect, arising out of or in any way
                    related to your use or inability to use the services,
                    including but not limited to damages allegedly arising from
                    the compromise or loss of your login credentials or funds,
                    or loss of or inability to restore access from your backup
                    phrase, or for mistakes, omissions, interruptions, delays,
                    defects and/or errors in the transmission of transactions or
                    messages.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    In no event shall Keyruptive, nor its directors, employees,
                    partners, agents, suppliers, or affiliates, be liable for
                    any indirect, incidental, special, consequential or punitive
                    damages, including without limitation, loss of profits,
                    data, use, goodwill, or other intangible losses, resulting
                    from (i) your access to or use of or inability to access or
                    use the Service; (ii) any conduct or content of any third
                    party on the Service; (iii) any content obtained from the
                    Service; and (iv) unauthorized access, use or alteration of
                    your transmissions or content, whether based on warranty,
                    contract, tort (including negligence) or any other legal
                    theory, whether or not we have been informed of the
                    possibility of such damage, and even if a remedy set forth
                    herein is found to have failed of its essential purpose.
                  </Text>
                </div>
              </div>
            </div>
            <div className="Privacy-bullet">
              <Subtitle>Governing Law</Subtitle>
              <div className="Privacy-textContainer">
                <div className="Privacy-text">
                  <Text>
                    These Terms shall be governed and construed in accordance
                    with the laws of Portugal, without regard to its conflict of
                    law provisions.
                  </Text>
                </div>
                <div className="Privacy-text">
                  <Text>
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect.
                  </Text>
                </div>
              </div>
            </div>
            <div className="Privacy-bullet">
              <div className="Privacy-textContainer">
                <div className="Privacy-text">
                  <p className="Privacy-footer">
                    Last reviewed October 2, 2019
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
