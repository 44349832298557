import React from 'react'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Privacy from '../components/Privacy'
import Footer from '../components/Footer'

const PrivacyPage = () => {
  if (typeof window !== 'undefined') {
    // Make scroll behavior of internal links smooth
    require('smooth-scroll')('a[href*="#"]')
  }

  return (
    <Layout>
      <Section>
        <Privacy />
      </Section>
      <Section>
        <Footer />
      </Section>
    </Layout>
  )
}

export default PrivacyPage
